import React, { Component } from 'react';
import { EditorContext } from "../page-editor";
import { commands } from "../../lib/inline-editor";
import { ContextMenuButton, ContextSubMenu, ContextMenuCheckbox, ContextMenu, MenuContext } from "@cargo/common/context-menu"
import { FRONTEND_DATA } from "../../globals";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../../actions";
import { adminCommerceProductFetchHelper } from "../ui-kit/helpers";

import _ from 'lodash';

class InsertContentContextMenu extends Component {

	constructor(props){
		super(props);

		this.state = {
			ranInitialProductFetch: false
		}

		this.tryRefocusPageOnClose = true;

		this.commandList = [{ 			
								name    : 'columns',
								component: import('../top-menu-bar/formatting-ui-windows/columns'),
								closeOnAllClickout: true,
								closeIfUnavailable: false },
							{ 
								name    : 'thumbnailindex'
							},
							{
								name    : 'audioplayer',
								tooltip : 'Audio',
								size    : 1,
								closeIfUnavailable: false, 
							},
							{
								name    : 'imagegallery',
								component: import('../top-menu-bar/formatting-ui-windows/imagegallery'),
								positionType: 'center',
								size    : 1,
								closeIfUnavailable: false 
							},
							{
								name    : 'iconpalette',
								component: import('../top-menu-bar/formatting-ui-windows/iconpalette'),
								tooltip : 'Icons',
								positionType: 'center-under-button',
								maxHeight: 70,
								size    : 1,
								closeIfUnavailable: false,
								custom: 'query-button-pos'
							},
							{
								name    : 'layouttemplate',
								positionType: 'center',
								size    : 1,
								closeIfUnavailable: false,
							},
							{
								name    : 'hr',
								size    : 1,
								closeIfUnavailable: false,
							},
							{
								name    : 'button-window',
								component: import('../top-menu-bar/formatting-ui-windows/button-window'),
								size    : 1,
								positionType: 'center',
								closeIfUnavailable: false,
							},
							{
								name    : 'loremipsum',
								window  : false,
								size    : 1,
								closeIfUnavailable: false,
							},
							{
								name: 'digitalclock',
								size    : 1,
							},
							{
								name: 'pageoptions',
								component: import('./../top-menu-bar/formatting-ui-windows/pageoptions'),
								positionType: 'center-under-button',
								preventEsc: true,
								acceptDrops: true,
								forceSubmenuOpen: 'flying-object'
							},
							{
								name:'page-backdrop-window',
								component: import('../right-menu-bar/page-backdrop-window'),
								positionType: 'center',
								preventEsc: true,
								acceptDrops: true
							}]

	}

	componentDidMount = () => {
		if( this.props.hasCommerceShop && !this.state.ranInitialProductFetch ){
			adminCommerceProductFetchHelper(this.props.productFetchRequired, this.props.fetchCommerceProducts, this.setProductFetchedState, this.props.updateAdminState);
		}
	}

	setProductFetchedState = (bool) => {
		this.setState({
			ranInitialProductFetch: bool
		})
	}

	render(){
		return <>
			{ this.props.elementRect ? (
				<ContextMenuButton
					label="Add:"
					disabled
				/>
			): (null )}
			<ContextMenuButton 
				label="Columns"
				onPointerUp={(e)=>{ this.callCommand(e, 'columns') }}
				disabled={this.getButtonState('columns') !== 'available' ? true : false}
			/>
			<ContextMenuButton 
				label="Gallery..."
				onPointerUp={(e)=>{ this.callCommand(e, 'imagegallery') }}
				disabled={this.getButtonState('imagegallery') !== 'available' ? true : false}
			/>
			<ContextMenuButton 
				label="Thumbnail Index"
				onPointerUp={(e)=>{ this.callCommand(e, 'thumbnailindex') }}
				disabled={this.getButtonState('thumbnailindex') !== 'available' ? true : false}
			/>
			<ContextMenuButton 
				label="Video Embed..."
				onPointerUp={(e)=>{
						const buttonPos = document.querySelector('[button-command="layouttemplate"] .select-arrows').getBoundingClientRect();					
						this.props.addUIWindow({
							group: 'formatting',
							component: import('./../top-menu-bar/add-url'),
							id: 'add-url',
							props: {

								positionType: 'center',
								type         		: 'popover',
								windowName   		: 'add-url',
								buttonPos    		: buttonPos,

							    clickoutLayer: true,
								clickoutLayerDim: true,							    
		                        disableDragging: true,
		                        closeOnSingleClickout: true,
		                        invokeTarget: e.currentTarget,
							}
						}, {
							removeGroup: false,
						});
				 }}
				disabled={false}
			/>

			<ContextMenuButton 
				label="Button..."
				onPointerUp={(e)=>{ this.callCommand(e, 'button-window') }}
				disabled={ false }
			/>
			<hr/>
			<ContextSubMenu 
				label="More..."
				layer={0}
			>
				<ContextMenuButton 
					label="Lorem Ipsum"
					onPointerUp={(e)=>{ this.callCommand(e, 'loremipsum') }}
					disabled={this.getButtonState('loremipsum') !== 'available' ? true : false}
				/>
				<ContextMenuButton 
					label="Horizontal Rule"
					onPointerUp={(e)=>{ this.callCommand(e, 'hr') }}
					disabled={this.getButtonState('hr') !== 'available' ? true : false}
				/>
				<ContextMenuButton
			    	label={"Backdrop..."}
			        onPointerUp={(e)=>{ this.callCommand(e, 'page-backdrop-window') }}
			        disabled={false}
	            />
    			{/* <ContextMenuButton
    				className="disabled"
    		    	label={"Sticker..."}
    		        onPointerUp={(e)=>{ this.callCommand(e, 'pageoptions', 'sticker') }}
    		        disabled={this.getButtonState('pageoptions') !== 'available' ? true : false}
                /> */}
    			<ContextMenuButton
    		    	label={"Flying Object..."}
    		        onPointerUp={(e)=>{ this.callCommand(e, 'pageoptions', 'flying-object') }}
    		        disabled={this.getButtonState('pageoptions') !== 'available' ? true : false}
                />
				<ContextMenuButton
			    	label={"Audio Player"}
			        onPointerUp={(e)=>{ this.callCommand(e, 'audioplayer') }}
			        disabled={this.getButtonState('audioplayer') !== 'available' ? true : false}
	            />
    			<ContextMenuButton
    		    	label={"Icon..."}
    		        onPointerUp={(e)=>{ this.callCommand(e, 'iconpalette') }}
    		        disabled={this.getButtonState('iconpalette') !== 'available' ? true : false}
                />
    			<ContextMenuButton
    		    	label={"Clock..."}
    		        onPointerUp={(e)=>{ this.callCommand(e, 'digitalclock') }}
    		        disabled={this.getButtonState('digitalclock') !== 'available' ? true : false}
                />                
                
				<ContextMenuButton
					label={"Product..."}
					disabled={ !this.state.ranInitialProductFetch || !this.props.hasProducts }
					onPointerUp = { (e) => {
						let firstProduct = Object.keys(this.props.hasProducts)[0]
						let productId = this.props.hasProducts[firstProduct].product_id ? this.props.hasProducts[firstProduct].product_id : null;
						let variantId = this.props.hasProducts[firstProduct]?.variants?.[0]?.variant_id ? this.props.hasProducts[firstProduct].variants[0].variant_id : null;
						commands['commerce'].execute({product: productId, variant: variantId});
					}}
				/>
				{/* )} */}


			</ContextSubMenu>

		</>
	}

	getButtonState( commandName ) {

		// FORMATTING BUTTON STATE NOMENCLATURE
		// applied 		= a single option that is either applied to the range or not
		// in-use 		= the selection is using or is within something that requires additional decisions (via uiWindow or menu) 
		// unavailable 	= the range cannot support this formatting option
		// available 	= the range supports this option, but is not in-use or is not applied
		const command       = commands[commandName];

		// FALLBACK — fallback condition if we render without a range or an editor
		if( !command || !this.context.range ) {
			return 'unavailable';
		}

		if( !this.context.range.commands[commandName] ){

			return 'unavailable';
		}

		let commandIsApplied = false;

		if( this.context.range.commands[commandName].isApplied ){
			commandIsApplied = true
		}

		if( this.props.alternateCommand ){
			_.each( this.props.alternateCommand, (command) => {
				if( this.context.range.commands[command].isApplied ){
					commandIsApplied = true;
				}
			})
		}

		const commandIsAllowed = this.context.range.commands[commandName].isAllowed;

		// APPLIED / IN USE
		// command is currently applied to the selected range
		if( commandIsApplied && commandIsAllowed && !command.ignoreButtonInUseState) {
		 	return 'in-use';

		// AVAILABLE / CAN BE USED
		} else if (!commandIsApplied && commandIsAllowed) {
			return 'available';
		}

		// DEFAULT = UNAVAILABLE
		return 'unavailable';

	}

	callCommand = (e, name, component, submenuForceOpen) => {

		const commandConfig = _.find(this.commandList, command => command.name === name);

		// prevent default so focus is not lost in the editor
		e.preventDefault();

		this.tryRefocusPageOnClose = false;

		if( commandConfig.name === 'page-backdrop-window' ){

			this.props.addUIWindow({
				group: 'formatting',
				component: commandConfig.component,
				id: 'page-backdrop-window',
				props: {
					type: 'popover',
					positionType: 'center-under-button',
					buttonPos: this.props.buttonPos,
					preventEsc: true,
					windowName: 'backdrop-settings',
					acceptDrops: true
				}
				
			},{
				removeGroup: true,
				removeAll: true
			})

			return
		}

		if( commandConfig.name === 'button-window') {
			
			this.props.addUIWindow({
				group: 'formatting',
				component: commandConfig.component,
				id: `formatting/button-window`,
				props: {
					type         		      : 'popover',
					windowName   		      : 'button-window',
					positionType 		      : commandConfig.positionType,
					closeOnAllClickout 	      : commandConfig.closeOnAllClickout ? true : null,
					closePrioritizedByCommand : commandConfig.closePrioritizedByCommand ? commandConfig.closePrioritizedByCommand : null,
					forceSubmenuOpen 		  : submenuForceOpen
				}
			}, {
				removeGroupByName: 'right-menu-bar',
			});

		}

		// do nothing if the button is available
		// if( this.buttonState === 'unavailable') return;
		let suppressWindow = false;
		const commandName = commandConfig.name,
			  command = commands[commandName],
			  commandState = this.context.range.commands[commandName] || {};

		// Command Execution
		if(	command 
			&& command.execute 
			&& commandState.isAllowed 
		) {
				// execute the command
				command.execute();

				// suppress the window after executing, but only for first execution
				if ( !commandState.isApplied ){
					suppressWindow = command.suppressWindowAfterExecution ? true : false;	
				}
				
		}
		// Window Opening: if the button has an associated window that is not suppressed, open it
		if ( commandState.isAllowed && commandConfig.component && !suppressWindow ) {

			let buttonPos = this.props.buttonPos

			if( commandConfig?.custom === 'query-button-pos' ){
				buttonPos = document.querySelector('[button-command="iconpalette"]').getBoundingClientRect();
			}

			if( commandConfig.name === 'pageoptions' ){
				this.props.addUIWindow({
					group: 'formatting',
					component: commandConfig.component,
					id: `pageoptions-window`,
					props: {
						type         		      : 'popover',
						buttonPos    		      : buttonPos,
						windowName   		      : 'pageoptions',
						positionType 		      : commandConfig.positionType,
						closeOnAllClickout 	      : commandConfig.closeOnAllClickout ? true : null,
						closePrioritizedByCommand : commandConfig.closePrioritizedByCommand ? commandConfig.closePrioritizedByCommand : null,
						forceSubmenuOpen 		  : submenuForceOpen
					}
				}, {
					removeGroupByName: 'right-menu-bar',
				});

				return;
			}

			this.props.addUIWindow({
				group: 'formatting',
				component: commandConfig.component,
				id: `formatting/${commandConfig.name}`,
				props: {
					type         		      : 'popover',
					buttonPos    		      : buttonPos,
					windowName   		      : commandConfig.name,
					positionType 		      : commandConfig.positionType,
					closeOnAllClickout 	      : commandConfig.closeOnAllClickout ? true : null,
					closePrioritizedByCommand : commandConfig.closePrioritizedByCommand ? commandConfig.closePrioritizedByCommand : null,
					forceSubmenuOpen 		  : submenuForceOpen
				}
			}, {
				removeGroupByName: 'right-menu-bar',
			});

		}

	}

	componentWillUnmount = () => {

		if( this.tryRefocusPageOnClose ){

			const insertContextClosed = new CustomEvent('insert-context-menu-closed', {
				detail: { 
					cachedRange: this.props.cachedRange
				} 
			});

			FRONTEND_DATA.contentWindow.dispatchEvent( insertContextClosed );
		}
		
	}



}

InsertContentContextMenu.contextType = EditorContext;

function mapReduxStateToProps(state, ownProps) {
	return {
		hasProducts: !_.isEmpty(state.commerce.products) ? state.commerce.products : null,
		hasCommerceShop: state.site.shop_id !== null,
		productFetchRequired: state.adminState.productFetchRequired,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow,
		fetchCommerceProducts: actions.fetchCommerceProducts,
		updateAdminState: actions.updateAdminState
	}, dispatch);
}

export default connect(
	mapReduxStateToProps,
	mapDispatchToProps
)(InsertContentContextMenu);
